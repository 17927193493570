import I18n from 'i18n';
import Snack from '@effectivastudio/snackbar';
import DataBinder from 'src/lib/data_binder';

function GroupSchema(d) {
  if (d.isSubscribed === 'toggle') {
    d.isSubscribed = this.hasText('.js-toggle-subscribe span', I18n.t('group.subscribe.title'));
    d.isSubscribedText = I18n.t(d.isSubscribed ? 'group.unsubscribe.title' : 'group.subscribe.title');
    Snack({ text: I18n.t(d.isSubscribed ? 'group.subscribe.success_msg' : 'group.unsubscribe.success_msg') });

    this.attr('.js-toggle-subscribe ef-icon', 'name', d.isSubscribed ? 'bell:solid' : 'bell');
  }

  if (d.isFavorited === 'toggle') {
    d.isFavorited = this.hasText('.js-toggle-favorite span', I18n.t('common.add_to_favorites.label'));
    d.isFavoritedText = I18n.t(d.isFavorited ? 'common.remove_from_favorites.label' : 'common.add_to_favorites.label');

    const parent = this.el.closest('xs-group-item-5');
    if (parent) (parent.favorite = !parent.favorite);

    this.attr('.js-toggle-favorite ef-icon', 'name', d.isFavorited ? 'star:solid' : 'star');
  }

  if (d.isBlocked === 'toggle') {
    d.isBlocked = this.hasText('.js-toggle-block span', I18n.t('group.block.title'));
    d.isBlockedText = I18n.t(d.isBlocked ? 'group.unblock.title' : 'group.block.title');
    this.attr('.js-toggle-block', 'data-confirm', d.isBlocked ? null : I18n.t('user_blockings.block_group_confirmation_modal'));
  }

  if (d.snackMsg) {
    if (d.snackMsg === 'favorite') {
      Snack({ text: I18n.t($(this.el).hasClass('active') ? 'common.add_to_favorites.success_msg' : 'common.remove_from_favorites.success_msg') });
    }
    if (d.snackMsg === 'blocked') {
      Snack({ text: I18n.t($(this.el).hasClass('active') ? 'group.block.success_msg' : 'group.unblock.success_msg') });
    }
  }

  this
    .toggleClass('.js-toggle-favorite', 'active', d.isFavorited)
    .toggleClass('.js-toggle-subscribe', 'active', d.isSubscribed)
    .toggleClass('.js-toggle-block', 'active', d.isBlocked)
    .text('.js-toggle-favorite span', d.isFavoritedText)
    .text('.js-toggle-subscribe span', d.isSubscribedText)
    .text('.js-toggle-block span', d.isBlockedText);
}

export default DataBinder.create(GroupSchema);
