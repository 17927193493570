import I18n from 'i18n';
import GroupBinder from 'src/data_binders/group_binder';
import { DataBinderMapper } from 'src/lib/data_binder';
import { reloadElement } from 'src/utils';

const RULES = {
  'js-toggle-subscribe': { isSubscribed: 'toggle' },
  'js-toggle-block': { isBlocked: 'toggle' },
  'js-toggle-favorite': { isFavorited: 'toggle' },
};

// For group members: Toggle Subscribe and Contact Person
$(document)
  .on('ajax:error ajax:beforeSend', '.member', (e) => {
    GroupBinder(e.target, DataBinderMapper(e.target, RULES));
  });

// Group list
$(document)
  .on('ajax:error ajax:beforeSend', '.js-group', (e) => {
    GroupBinder(e.currentTarget, DataBinderMapper(e.target, RULES));
  });

$(document)
  .on('ajax:success:inline', '.js-description-update', function onAjaxSuccessDescriptionUpdate() {
    $(this).trigger('updateGroupDescription:done');
  })
  .on('ajax:error:inline', '.js-description-update', function onAjaxErrorDesciptionUpdate() {
    $(this).trigger('updateGroupDescription:fail');
  });

// Accept / Reject group request to join
// Homepage + Group invites
$(document)
  .on('ajax:beforeSend', '.js-accept-request', function onBeforeSendAcceptRequest() {
    $(this).closest('[data-hide-id]').remove();
  })
  .on('ajax:success', '.js-accept-request', function onSuccessAcceptRequest() {
    $(this).closest('.member').promise().done(() => {
      reloadElement('.js-group-invites');
    });
  });

$(document).on('ajax:success', '.js-toggle-favorite', (e) => {
  GroupBinder(e.currentTarget, { snackMsg: 'favorite' });
});

// TODO: Refactor once we implement a group dot menu.
$(document).on('ajax:success', '.js-toggle-subscribe, .js-toggle-subscribe-2', (e) => {
  const el = document.querySelector('.js-toggle-subscribe-2 button');

  if (!el) { return; }

  const elSpan = el.querySelector('span');
  const elIcon = el.querySelector('ef-icon');
  const isSubscribed = el.classList.contains('btn-primary');

  el.classList.toggle('btn-primary');
  el.classList.toggle('btn-secondary');
  elIcon.name = isSubscribed ? 'bell-slash' : 'bell';
  elSpan.innerText = I18n.t(isSubscribed ? 'group.unsubscribe.title' : 'group.subscribe.title');

  if (e.currentTarget.tagName === 'FORM') {
    const el2 = document.querySelector('.js-toggle-subscribe');

    GroupBinder(el2, { isSubscribed: 'toggle' });
  }
});

$(document).on('ajax:success', '.js-toggle-block', (e) => {
  GroupBinder(e.currentTarget, { snackMsg: 'blocked' });
});
